import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const Image = () => {
  const [sz, setSz] = useState("250px")

  useEffect(() => setSz("300px"), [])

  return (
    <div
      className="ml-auto mr-auto"
      style={{
        width: "100%",
        maxWidth: sz,
        margin: "1em auto 1em auto",
        transition: "max-width 1s",
      }}
    >
      <StaticImage
        style={{
          borderRadius: "50%",
        }}
        src="../images/VnP_profile.jpg"
        alt="Vishwanath 'Prem'"
      />
    </div>
  )
}

export default Image
