import React, { useState, useEffect } from "react"

import Image from "../components/image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from "../components/frontpage/title"
import SocialIcons from "../components/frontpage/socialIcons"
import Snippet from "../components/frontpage/snippet"

const IndexPage = () => {
  const [disp, setDisp] = useState(0)
  const [pos, setPos] = useState("40px")

  useEffect(() => {
    setDisp(1)
    setPos("0px")
  }, [])

  return (
    <Layout isHome={true}>
      <Seo title="Home" />
      <Title />
      <Image />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          opacity: disp,
          position: "relative",
          bottom: pos,
          transition: "all 1s",
        }}
      >
        <SocialIcons />
      </div>
      <Snippet />
    </Layout>
  )
}

export default IndexPage
