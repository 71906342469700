import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

function Snippet() {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    setTimeout(function() {
      setOpacity(1)
    }, 200)
  }, [])

  return (
    <div
      style={{
        marginTop: "1em",
        textAlign: "center",
      }}
    >
      <Link class="snippet" style={{ opacity: opacity }} to="/manis_ko_khoji/">
        छाडेर काम सारा, एक काम रोजिरेछु
        <br />
        यो देशमा म एउटा मानिस खोजिरेछु
      </Link>
    </div>
  )
}

export default Snippet