import React, { useState, useEffect } from "react"

function Title(props) {
  const [disp, setDisp] = useState(0)
  const [pos, setPos] = useState("-40px")

  useEffect(() => {
    setDisp(1)
    setPos("0px")
  }, [])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        alignItems: "center",
        opacity: disp,
        transition: "all 1s",
        position: "relative",
        bottom: pos,
      }}
    >
      <h1
        style={{
          color: "white",
          margin: "0 0 -0.3em 0",
          fontFamily: "`Khula`, sans-serif",
          fontSize: "2.5em",
        }}
      >
        विश्वनाथ <span style={{ color: "#d94040" }}>'प्रेम'</span>
      </h1>
      <h2
        style={{
          color: "#878787",
          margin: "0",
          fontSize: "1.5em",
          lineHeight: "1em",
        }}
      >
        गीतकार, संगीतकार, कवि...
      </h2>
    </div>
  )
}

export default Title
